import React from "react";

function Footer() {
  return (
    <div>
      <div className="bg-[#2f2f2f61] text-white monoSpace w-full mt-10 text-sm">
        <h1 className="m-auto p-4">
          Follow me on{" "}
          <a
            href="https://www.twitter.com/haruxeETH"
            target={"_blank"}
            className="text-blue-500"
          >
            Twitter
          </a>
          ! Created by haruxe.eth
        </h1>
      </div>
    </div>
  );
}

export default Footer;
